<template>
  <div
    class="payment-invoice flex-column"
    v-if="billingData && billingData.person"
  >
    <v-checkbox
      v-model="needInvoice"
      true-value="true"
      false-value="false"
      class="privacy-check mb-2"
      hide-details
      required
    >
      <template v-slot:label>
        <div class="d-flex flex-column default--text">
          <span class="text-h5 font-weight-bold">{{
            $t("paymentInvoice.question")
          }}</span>
          <i18n path="paymentInvoice.answerYes" tag="div"> </i18n>
        </div>
      </template>
    </v-checkbox>
    <transition-expand>
      <v-card :loading="loading" elevation="0">
        <v-form
          ref="form"
          v-if="needInvoice == 'true'"
          v-model="valid"
          :lazy-validation="lazy"
          :disabled="isReadOnly"
          @submit.prevent="updateBillingDetails"
        >
          <v-row>
            <v-col cols="12 " class="pb-0">
              <v-radio-group
                v-model="billingData.person.personTypeId"
                mandatory
                row
                class="w-100"
                :class="{ 'd-flex flex-column mt-0': $vuetify.breakpoint.xs }"
                @change="validate"
              >
                <v-radio
                  color="default"
                  :class="$vuetify.breakpoint.xs ? 'w-100' : 'w-30'"
                  :value="1"
                  ><template v-slot:label>
                    <span class="ml-2 text-h5 font-weight-bold">{{
                      $t("paymentInvoice.userPrivate")
                    }}</span>
                  </template></v-radio
                >
                <v-radio
                  color="default"
                  :class="$vuetify.breakpoint.xs ? 'w-100' : 'w-30'"
                  :value="2"
                  ><template v-slot:label>
                    <span class="ml-2 text-h5 font-weight-bold">{{
                      $t("paymentInvoice.userCompany")
                    }}</span>
                  </template></v-radio
                >
                <v-radio
                  color="default"
                  :class="$vuetify.breakpoint.xs ? 'w-100' : 'w-30'"
                  :value="3"
                  ><template v-slot:label>
                    <span class="ml-2 text-h5 font-weight-bold">{{
                      $t("paymentInvoice.individualCompany")
                    }}</span>
                  </template></v-radio
                >
              </v-radio-group>
            </v-col>
          </v-row>
          <!-- PERSONA FISICA -->
          <template v-if="billingData.person.personTypeId == 1">
            <v-row>
              <v-col cols="12" sm="6" class="pb-0">
                <v-text-field
                  v-model="billingData.person.firstName"
                  :label="$t('paymentInvoice.firstName')"
                  class="rounded-lg"
                  clearable
                  filled
                  solo
                  required
                  @blur="$refs.form.validate()"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" class="pb-0">
                <v-text-field
                  v-model="billingData.person.lastName"
                  :label="$t('paymentInvoice.lastName')"
                  class="rounded-lg"
                  clearable
                  filled
                  solo
                  required
                  @blur="$refs.form.validate()"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" class="pb-0">
                <v-text-field
                  v-model="billingData.person.fiscalCode"
                  :label="$t('paymentInvoice.fiscalCode')"
                  :rules="cfRules"
                  class="rounded-lg"
                  clearable
                  filled
                  solo
                  required
                  @blur="$refs.form.validate()"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col col="12" sm="9" class="pb-0">
                <v-text-field
                  v-model="billingData.billingAddress.address1"
                  :label="$t('paymentInvoice.address')"
                  :rules="[requiredValue($t('paymentInvoice.address'))]"
                  required
                  class="rounded-lg"
                  clearable
                  filled
                  solo
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="3" class="pb-0">
                <v-text-field
                  v-model="billingData.billingAddress.addressNumber"
                  :label="$t('paymentInvoice.addressNumber')"
                  :rules="[requiredValue($t('paymentInvoice.addressNumber'))]"
                  required
                  class="rounded-lg"
                  clearable
                  filled
                  solo
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" class="pb-0">
                <v-text-field
                  v-model="billingData.billingAddress.city"
                  :label="$t('paymentInvoice.city')"
                  :rules="[requiredValue('Città obbligatoria')]"
                  required
                  class="rounded-lg"
                  clearable
                  filled
                  solo
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="3" class="pb-0">
                <v-text-field
                  v-model="billingData.billingAddress.postalcode"
                  :label="$t('paymentInvoice.postalCode')"
                  :rules="[requiredValue('CAP obbligatorio')]"
                  required
                  class="rounded-lg"
                  clearable
                  filled
                  solo
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="3" class="pb-0">
                <v-text-field
                  v-model="billingData.billingAddress.province"
                  :label="$t('paymentInvoice.province')"
                  :rules="provinceRules"
                  required
                  max-length
                  class="rounded-lg"
                  clearable
                  filled
                  solo
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6" class="pb-0">
                <v-text-field
                  v-model="billingData.person.emailCertified"
                  :label="`@${$t('paymentInvoice.pec')}`"
                  :rules="emailPecRules"
                  class="rounded-lg"
                  clearable
                  filled
                  solo
                  @blur="$refs.form.validate()"
                ></v-text-field>
              </v-col>
            </v-row>
          </template>

          <!-- AZIENDA o DITTA INDIVIDUALE -->
          <template
            v-else-if="
              billingData.person.personTypeId == 2 ||
                billingData.person.personTypeId == 3
            "
          >
            <v-row>
              <v-col cols="12" sm="12" class="pb-0">
                <v-text-field
                  v-model="billingData.person.company"
                  :label="$t('paymentInvoice.company')"
                  :rules="[requiredValue('Ragione sociale')]"
                  required
                  class="rounded-lg"
                  clearable
                  filled
                  solo
                  @blur="$refs.form.validate()"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6" class="pb-0">
                <v-text-field
                  v-model="billingData.person.vatCode"
                  :label="$t('paymentInvoice.vatCode')"
                  :rules="cfIvaRules"
                  required
                  class="rounded-lg"
                  clearable
                  filled
                  solo
                  @blur="$refs.form.validate()"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" class="pb-0">
                <v-text-field
                  v-model="billingData.person.vatFiscalCode"
                  :label="$t('paymentInvoice.vatFiscalCode')"
                  class="rounded-lg"
                  clearable
                  filled
                  solo
                  required
                  :rules="cfIvaRules"
                  @blur="$refs.form.validate()"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col col="12" sm="9" class="pb-0">
                <v-text-field
                  v-model="billingData.billingAddress.address1"
                  :label="$t('paymentInvoice.address')"
                  :rules="[requiredValue($t('paymentInvoice.address'))]"
                  required
                  class="rounded-lg"
                  clearable
                  filled
                  solo
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="3" class="pb-0">
                <v-text-field
                  v-model="billingData.billingAddress.addressNumber"
                  :label="$t('paymentInvoice.addressNumber')"
                  :rules="[requiredValue($t('paymentInvoice.addressNumber'))]"
                  required
                  class="rounded-lg"
                  clearable
                  filled
                  solo
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" class="pb-0">
                <v-text-field
                  v-model="billingData.billingAddress.city"
                  :label="$t('paymentInvoice.city')"
                  :rules="[requiredValue('Città obbligatoria')]"
                  required
                  class="rounded-lg"
                  clearable
                  filled
                  solo
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="3" class="pb-0">
                <v-text-field
                  v-model="billingData.billingAddress.postalcode"
                  :label="$t('paymentInvoice.postalCode')"
                  :rules="[requiredValue('CAP obbligatorio')]"
                  required
                  class="rounded-lg"
                  clearable
                  filled
                  solo
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="3" class="pb-0">
                <v-text-field
                  v-model="billingData.billingAddress.province"
                  :label="$t('paymentInvoice.province')"
                  :rules="provinceRules"
                  required
                  max-length
                  class="rounded-lg"
                  clearable
                  filled
                  solo
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6" class="pb-0">
                <v-text-field
                  v-model="billingData.person.emailCertified"
                  :label="`@${$t('paymentInvoice.pec')}`"
                  :rules="emailPecRules"
                  class="rounded-lg"
                  clearable
                  filled
                  solo
                  @blur="$refs.form.validate()"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" class="pb-0">
                <v-text-field
                  v-model="billingData.person.vatSdiCode"
                  :label="$t('paymentInvoice.vatSdiCode')"
                  :rules="sdiRules"
                  class="rounded-lg"
                  clearable
                  filled
                  solo
                  @blur="$refs.form.validate()"
                ></v-text-field>
              </v-col>
            </v-row>
          </template>

          <v-row>
            <v-col cols="12" class="d-flex justify-end">
              <v-btn
                v-if="!isReadOnly"
                type="submit"
                x-large
                color="error"
                depressed
                :disabled="disabledUpdate"
              >
                {{ $t("paymentInvoice.save") }}
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card>
    </transition-expand>
  </div>
</template>

<script>
import TransitionExpand from "@/components/TransitionExpand.vue";
import RegistrationService from "~/service/userService";
import { req } from "~/validator/commons";

import { mapActions, mapGetters } from "vuex";
import { mapCartInfo } from "~/service/ebsn";

import {
  requiredValue,
  isEmail,
  isCF,
  isCfIva,
  isLength
} from "~/validator/validationRules";

export default {
  name: "PaymentInvoice",
  data() {
    return {
      menu: false,
      loading: false,
      valid: false,
      lazy: false,
      dirty: false,
      address: null,
      hasBirthDate: false,
      billingData: {},
      requiredValue: requiredValue,
      provinceRules: [
        requiredValue("Provincia obbligatoria"),
        isLength(2, "La provincia deve avere esattamente 2 caratteri")
      ],
      userPrivate: null,
      cfRules: [requiredValue(), isCF()],
      emailRules: [
        (this.billingData && !this.billingData.person.emailCertified) ||
          isEmail()
      ],
      emailPecRules: [
        v => {
          if (v) {
            return (
              !req(v) ||
              /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(v) ||
              "Digitare una e-mail valida"
            );
          } else if (
            this.billingData &&
            !this.billingData.person.emailCertified
          ) {
            return "PEC obbligatoria";
          } else {
            return true;
          }
        }
      ],
      sdiRules: [
        v => {
          if (v) {
            return /^[A-Z0-9]{6,7}$/.test(v) || "Codice SDI non valido";
          } else if (this.billingData && !this.billingData.person.vatSdiCode) {
            return "SDI obbligatorio";
          } else {
            return true;
          }
        }
      ],
      cfIvaRules: [requiredValue(), isCfIva()],
      postalcodeList: [],
      city: null,
      address1: null
    };
  },
  components: [TransitionExpand],
  computed: {
    ...mapGetters({
      serviceId: "cart/getSelectedService"
    }),
    disabledUpdate() {
      return !this.valid;
    },
    isReadOnly() {
      // if (this.userPrivate) {
      //   return this.billingData.person.fiscalCode.length > 0;
      // } else {
      //   return (
      //     this.billingData.person.vatCode.length > 0 &&
      //     this.billingData.person.company.length > 0
      //   );
      // }
      return false;
    },
    ...mapCartInfo({ needInvoice: "need_invoice" })
  },
  methods: {
    ...mapActions({
      setCartInfo: "cart/setCartInfo"
    }),
    async handleInvoiceChange() {
      if (this.needInvoice) {
        this.validate();
      } else {
        this.$emit("billingFormCompleted", true);
      }
    },
    changed() {
      this.dirty = true;
    },
    validate() {
      let _this = this;
      setTimeout(function() {
        if (_this.$refs.form) {
          _this.valid = _this.$refs.form.validate();
          _this.$emit("billingFormCompleted", _this.valid);
        }
      }, 200);
    },
    async updateBillingDetails() {
      if (this.$refs.form.validate()) {
        let res = await RegistrationService.updateBillingAddress(
          this.billingData
        );

        if (res && res.billingAddress) {
          this.billingData = res;
          if (this.billingData.person.birthDate) {
            this.hasBirthDate = true;
          }
        }
        this.dirty = false;
        this.validate();
      }
    },
    async getUserDetail() {
      let res = await RegistrationService.getUserDetail();
      this.billingData = res;
      this.userPrivate = res.person.personTypeId;
      if (res.person.birthDate) {
        this.hasBirthDate = true;
      }
      console.log("user: ", res);
    }
  },
  async mounted() {
    await this.getUserDetail();
    this.validate();
  }
};
</script>
