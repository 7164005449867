<template>
  <div class="">
    <PaymentStepper :step="2" />
    <v-container class="d-flex flex-column scroll-container">
      <CategoryTitle :category="category" class="px-0" />
      <v-row>
        <v-col cols="12" md="8" order="last" order-sm="first">
          <PaymentInvoice
            v-if="showInvoice"
            @billingFormCompleted="billingFormCompleted"
          />

          <PaymentTypeList
            :cart="cart"
            mode="cart"
            :isPaymentDisabled="!billingFormCompletedInternal && invoiceNeeded"
            :options="paymentTypeListOptions"
            :defaultPaymentType="-1"
            :autoSelectPaymentType="false"
          />
          <back-button @back="goBack" />
        </v-col>
        <!-- :cartInfos="cartInfos" -->
        <v-col
          cols="12"
          md="4"
          order="first"
          order-sm="last"
          v-if="cart.shippingAddress"
        >
          <CheckoutSummary :orderCart="cart" class="d-none d-md-block" />
        </v-col>
      </v-row>
    </v-container>
    <v-spacer class="spacer-scroll"></v-spacer>
  </div>
</template>

<script>
import CategoryTitle from "@/components/category/CategoryTitle.vue";
import CheckoutSummary from "@/components/cart/CheckoutSummary.vue";
import PaymentTypeList from "~/components/payment/PaymentTypeList.vue";
import PaymentInvoice from "@/components/payment/PaymentInvoice.vue";
import PaymentStepper from "@/components/payment/PaymentStepper.vue";

import CartService from "~/service/cartService";
import AnalyticsService from "~/service/analyticsService";

import login from "~/mixins/login";
import cartInfo from "~/mixins/cartInfo";
import categoryMixins from "~/mixins/category";

import { mapCartInfo, mapProposalComponent } from "~/service/ebsn";
import { mapState } from "vuex";
import BackButton from "@/components/navigation/BackButton.vue";
export default {
  name: "Payment",
  data() {
    return {
      paymentTypeList: [],
      packagesList: [],
      isPaymentDisabled: false,
      billingFormCompletedInternal: false,
      paymentTypeListOptions: {
        flatPanels: true,
        showCardLogos: true,
        titleStyle: "default--text text-uppercase payment-type-list-title",
        back: {
          label: "Torna Indietro",
          class: "paymenbt-back-button",
          icon: "$prev",
          link: true,
          show: false
        }
      }
    };
  },
  components: {
    PaymentTypeList,
    CategoryTitle,
    CheckoutSummary,
    PaymentInvoice,
    PaymentStepper,
    BackButton
  },

  mixins: [login, cartInfo, categoryMixins],

  computed: {
    ...mapState({
      cart: ({ cart }) => cart.cart
    }),
    ...mapCartInfo({ needInvoice: "need_invoice" }),
    invoiceNeeded() {
      return this.needInvoice === "1" ? true : false;
    },
    ...mapProposalComponent({
      header: "header",
      footer: "footer",
      sidebox: "sidebox"
    }),
    addressClassShowInvoice() {
      let addressClasses = this.cart.shippingAddress.addressClass;
      let result = false;
      if (addressClasses && addressClasses.length) {
        for (let i = 0; i < addressClasses.length; i++) {
          if (addressClasses[i].addressClassGroup.addressClassGroupId == 3) {
            result = true;
            break;
          }
        }
      }
      return result;
    },
    showInvoice() {
      // non mostrare se c'è legalId 3 o se lo shippingAddress ha l'addressClass con group 3
      return !(
        this.$store.getters["cart/getLegalById"](3) ||
        this.addressClassShowInvoice
      );
    }
  },
  methods: {
    billingFormCompleted(completed) {
      this.billingFormCompletedInternal = completed;
    },
    async fetchPackages() {
      this.packagesList = await CartService.getPackages();
    },
    goBack() {
      this.$router.push("/checkout");
    }
  },
  mounted() {
    if (!this.showInvoice) {
      this.needInvoice = "true";
    }
  },
  created() {
    this.fetchPackages();
    AnalyticsService.checkoutProgress(this.cart);
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      if (vm.cart && vm.cart.totalItems > 0) {
        next();
      } else {
        next("/");
      }
    });
  }
};
</script>
