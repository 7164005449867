<template>
  <v-stepper
    v-model="step"
    :alt-labels="$vuetify.breakpoint.smAndDown"
    tile
    class="stepper mx-auto"
    elevation="0"
  >
    <v-stepper-header>
      <v-stepper-step step="1" color="primary lighten-1" :complete="step > 1">
        <span class="text-caption text-sm-body-2 font-weight-bold ">{{
          $t("paymentStepper.1")
        }}</span>
      </v-stepper-step>

      <v-divider></v-divider>

      <v-stepper-step step="2" color="primary lighten-1" :complete="step > 2">
        <span class="text-caption text-sm-body-2 font-weight-bold">{{
          $t("paymentStepper.2")
        }}</span>
      </v-stepper-step>

      <v-divider></v-divider>

      <v-stepper-step step="3" color="primary lighten-1" :complete="step > 3">
        <span class="text-caption text-sm-body-2 font-weight-bold">{{
          $t("paymentStepper.3")
        }}</span>
      </v-stepper-step>
    </v-stepper-header>
  </v-stepper>
</template>

<script>
export default {
  props: { step: { type: Number, default: 1 } }
};
</script>
<style scoped lang="scss">
.stepper {
  box-shadow: none;
  -webkit-box-shadow: none;
  @media screen and (max-width: 414px) {
    .v-stepper__header {
      .v-stepper__step {
        flex-basis: 0px;
      }
      .v-divider {
        margin: 38px -30px 0;
        -ms-flex-item-align: start;
        align-self: flex-start;
      }
    }
  }
  @media #{map-get($display-breakpoints, 'sm-only')} {
    max-width: 90%;
  }
  @media #{map-get($display-breakpoints, 'md-only')} {
    max-width: 80%;
  }
  @media #{map-get($display-breakpoints, 'lg-and-up')} {
    max-width: 60%;
  }
}
</style>
