<template>
  <v-btn color="white" text plain @click="$emit('back')">
    <v-icon class="primary white--text rounded-lg mr-3">$prev</v-icon>
    <i18n
      tag="span"
      class="primary--text mr-2 text-caption font-weight-bold text-none"
      v-html="label"
    />
  </v-btn>
</template>
<script>
export default {
  name: "BackButton",
  props: {
    label: {
      type: String,
      required: false,
      default: function() {
        return this.$t("common.back");
      }
    }
  }
};
</script>
