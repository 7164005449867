<template>
  <v-card class="py-3 px-3 text-left primary lighten-2 default--text" flat>
    <div class="text-h4 font-weight-bold text-uppercase">
      {{
        isOrderDetail ? $t("cartSummary.headerOrder") : $t("cartSummary.header")
      }}
    </div>

    <v-card-text class="text-body-2 pl-0 pr-5">
      <div class="d-flex justify-space-between align-top font-weight-bold">
        <span>{{ $t("cartSummary.totalPrice") }}</span>
        <span>
          {{ $n(netTotal, "currency") }}
        </span>
      </div>
      <div
        v-if="orderCart.totalPriceVariable > 0"
        class=" d-flex justify-space-between align-top text-caption"
      >
        <span>{{ $t("cartSummary.totalPriceVariable") }}</span>
        <span>{{ $n(orderCart.totalPriceVariable, "currency") }}</span>
      </div>

      <div
        v-if="discount != 0"
        class="d-flex text-uppercase justify-space-between align-top font-weight-bold"
      >
        <span>{{ $t("cartSummary.discount") }}</span>
        <span> -{{ $n(discount, "currency") }} </span>
      </div>

      <div
        class="d-flex justify-space-between align-top"
        v-for="orderPackage in orderCart.orderPackages"
        :key="orderPackage.orderPackageId"
      >
        <span
          >{{ orderPackage.package.descr }} <br />
          ({{ orderPackage.quantity }}x{{
            $n(orderPackage.package.cost, "currency")
          }})
        </span>
        <span>{{ $n(orderPackage.grossTotal, "currency") }}</span>
      </div>
      <div
        v-if="
          !isOrderDetail &&
            orderCart.giftCertificatesToGenerate &&
            orderCart.giftCertificatesToGenerate.length
        "
      >
        <div class="text-uppercase font-weight-bold mt-4">
          {{ $t("cartSummary.promo") }}
        </div>
        <div
          class="d-flex justify-space-between align-top"
          v-for="gift in orderCart.giftCertificatesToGenerate"
          :key="gift.giftCertificateId"
        >
          <span class="default--text text-caption text-none gift-name">
            {{ gift.name }}
          </span>
          <span v-if="gift.total">{{ `-${$n(gift.total, "currency")}` }}</span>
        </div>
      </div>
      <!-- standard gifts -->
      <div
        class="text-uppercase font-weight-bold mt-4"
        v-if="
          filteredStandardGiftCertificate &&
            filteredStandardGiftCertificate.length
        "
      >
        {{ $t("cartSummary.giftCertificates") }}
      </div>
      <div
        v-for="gift in filteredStandardGiftCertificate"
        :key="gift.giftCertificateId"
        class="d-flex flex-row align-center default--text text-caption text-none"
      >
        <v-row no-gutters justify="space-between">
          <span class="gift-name">{{ gift.name }}</span>
          <span v-if="gift.total > 0">{{
            `-${$n(gift.total, "currency")}`
          }}</span>
        </v-row>
        <v-btn
          v-if="!isOrderDetail"
          tile
          icon
          class="delete"
          @click="removeGiftCode(gift.userGiftCertificateId)"
        >
          <v-icon color="promo">$delete</v-icon>
        </v-btn>
      </div>

      <div
        class="d-flex justify-space-between align-top font-weight-bold"
        v-if="orderCart.deliveryFee > 0"
      >
        <span>{{ $t("cartSummary.deliveryFee") }}</span>
        <span>{{ $n(orderCart.deliveryFee, "currency") }}</span>
      </div>
      <div
        class="d-flex justify-space-between align-top mt-3 font-weight-bold"
        v-if="orderCart.packageTotal > 0"
      >
        <span>{{ $t("cartSummary.packageTotal") }}</span>
        <span>{{ $n(orderCart.packageTotal, "currency") }}</span>
      </div>
      <div
        class="d-flex text-uppercase justify-space-between align-top mt-3 font-weight-bold"
      >
        <span>{{ $t("cartSummary.orderGrossTotal") }}</span>
        <span>{{ $n(orderGrossTotal, "currency") }}</span>
      </div>
      <!-- refund -->
      <!-- <div
        class="text-uppercase font-weight-bold mt-4"
        v-if="filteredRefundGiftCertificate.length > 0"
      >
        {{ $t("cartSummary.refund") }}
      </div> -->
      <div
        v-for="gift in filteredRefundGiftCertificate"
        :key="gift.giftCertificateId"
        class="d-flex flex-row align-center default--text text-caption text-none text-uppercase font-weight-bold  mt-2"
      >
        <span class="gift-name">{{ gift.name }}</span>
        <v-spacer />
        <span v-if="gift.total > 0">{{
          `-${$n(gift.total, "currency")}`
        }}</span>
        <v-btn
          v-if="!isOrderDetail"
          tile
          icon
          class="delete"
          @click="removeGiftCode(gift.userGiftCertificateId)"
        >
          <v-icon color="promo">$delete</v-icon>
        </v-btn>
      </div>
    </v-card-text>
    <div>
      <v-divider class="my-3" />
      <v-row
        no-gutters
        justify="space-between"
        align="center"
        class="text-h4 d-flex font-weight-bold text-uppercase pr-4"
      >
        <span>{{ $t("cartSummary.grossTotalToPay") }}</span>
        {{ $n(orderCart.grossTotal, "currency") }}
      </v-row>

      <v-row
        no-gutters
        justify="space-between"
        align="center"
        v-if="saved > 0"
        class="mt-0 text-caption text-uppercase success--text pr-4 font-weight-bold"
      >
        <span>{{ $t("cartSummary.saving") }}</span>
        <span>{{ $n(saved, "currency") }}</span>
      </v-row>
    </div>
  </v-card>
</template>
<style scoped lang="scss">
.delete {
  position: absolute;
  right: -2px;
}
.gift-name {
  max-width: 80%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
</style>
<script>
import { mapActions } from "vuex";
export default {
  name: "CheckoutSummary",
  props: {
    orderCart: { type: Object, required: true },
    isOrderDetail: { type: Boolean, default: false }
  },
  computed: {
    netTotal() {
      if (this.orderCart.orderId && this.orderCart.orderStatusId >= 8) {
        var refundTotalDel = this.orderCart.orderAdjustmentSummary
          .TYPE_REFUND_TOTAL.totalVariation;
        var discountItemTotalDel = this.orderCart.orderAdjustmentSummary
          .TYPE_ITEM_TOTAL.totalVariation;
        var discountNetTotalDel = this.orderCart.orderAdjustmentSummary
          .TYPE_NET_TOTAL.totalVariation;
        return (
          this.orderCart.grossTotal +
          refundTotalDel +
          discountItemTotalDel +
          discountNetTotalDel
        );
      }

      var netTotal = this.orderCart.orderId
        ? this.orderCart.netTotal
        : this.orderCart.totalPrice;
      var discountItemTotal = this.orderCart.orderAdjustmentSummary
        .TYPE_ITEM_TOTAL.totalVariation;
      return netTotal + discountItemTotal;
    },
    discount() {
      var discountItemTotal = this.orderCart.orderAdjustmentSummary
        .TYPE_ITEM_TOTAL_PROMO.totalVariation;
      return discountItemTotal;
      // return this.orderCart.giftTotal + this.orderCart.totalDiscount;
    },
    orderGrossTotal() {
      //  return this.orderCart.giftTotal + this.orderCart.totalDiscount;
      var refundTotal = this.orderCart.orderAdjustmentSummary.TYPE_REFUND_TOTAL
        .totalVariation;
      return this.orderCart.grossTotal + refundTotal;
    },

    saved() {
      return (
        this.orderCart.orderAdjustmentSummary.TYPE_ITEM_TOTAL.totalVariation +
        this.orderCart.orderAdjustmentSummary.TYPE_NET_TOTAL.totalVariation
      );
      // return this.orderCart.giftTotal + this.orderCart.totalDiscount;
    },
    filteredGiftCertificate() {
      return this.orderCart.giftCertificates.filter(gift => {
        return gift.total > 0;
      });
    },

    filteredStandardGiftCertificate() {
      return this.orderCart.giftCertificates.filter(gift => {
        return gift.giftCertificateId != 51;
      });
    },

    filteredRefundGiftCertificate() {
      return this.orderCart.giftCertificates.filter(gift => {
        return gift.giftCertificateId == 51;
      });
    }
  },
  methods: {
    ...mapActions({
      remove: "cart/removeGiftCode"
    }),
    async removeGiftCode(giftCodeId) {
      await this.remove(giftCodeId);
      this.$emit("removeGiftCode", giftCodeId);
    }
  }
};
</script>
